import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'
import moment from 'moment-timezone'
import { Thai } from 'flatpickr/dist/l10n/th'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useNovelList(FileSaver) {
  // Use toast
  const toast = useToast()

  const refNovelListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'createDate', label: 'วันที่ลงข้อมูล', sortable: true, class: 'v-novel-list-column-createdAt',
    },
    {
      key: 'noCovid', label: 'No.Covid', sortable: true, class: 'v-novel-list-column-noCovid',
    },
    {
      key: 'uniqueId', label: 'ID', sortable: false, class: 'v-novel-list-column-uniqueId',
    },
    { key: 'name', sortable: false },
    { key: 'actions', sortable: false, class: 'v-novel-list-column-action' },
  ]
  const userData = ref(JSON.parse(localStorage.getItem('userData')))
  const totalNovels = ref(0)
  const currentPage = ref(1)
  const pageSize = ref(10)
  const pageSizeOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const filterTeam = ref('')
  const filterPatientType = ref('')
  const filterCovidCurrentLevel = ref([])
  const filterCovidCurrentState = ref([])
  const teamChoices = ref([])
  const patientTypesChoices = ref([
    { text: 'ทุกประเภท', value: '' },
    { text: 'Non Covid', value: 'non-covid' },
    { text: 'Covid', value: 'covid' },
  ])
  const covidCurrentStateChoices = ref([
    { text: 'รอเตียง', value: 'wait_bed' },
    { text: 'Home Isolation', value: 'hi' },
    { text: 'Home Quarantine', value: 'hq' },
    { text: 'Community Isolation', value: 'ci' },
    { text: 'Admit', value: 'admit' },
    { text: 'จบเคส', value: 'end' },
    { text: 'ตาย', value: 'death' },
  ])
  const covidCurrentLevelChoices = ref([
    { text: 'เขียว', value: 'green' },
    { text: 'เหลือง', value: 'yellow' },
    { text: 'แดง', value: 'red' },
  ])

  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const filterRangeDate = ref(null)
  const filterRangeConfig = ref({
    mode: 'range',
    dateFormat: 'd-m-Y',
    locale: Thai,
  })
  const maxFilterDate = ref(new Date())

  const paginationMeta = computed(() => {
    const localItemsCount = refNovelListTable.value ? refNovelListTable.value.localItems.length : 0
    return {
      from: pageSize.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (currentPage.value - 1) + localItemsCount,
      of: totalNovels.value,
    }
  })

  const getTeamChoices = async () => {
    const { data } = await axios.get('/staffs/create/team-choices')
    teamChoices.value = [
      { text: 'ทั้งหมด', value: '' },
      ...data,
    ]
  }
  if (userData.value.role === 'admin') (getTeamChoices())

  const refetchData = async () => {
    refNovelListTable.value.refresh()
  }

  watch([currentPage, pageSize], () => {
    refetchData()
  })

  const downloadExcelSwabList = () => {
    let startDate = null
    let endDate = null
    if (filterRangeDate.value) {
      const dates = filterRangeDate.value.split(' ')
      if (dates.length === 3) {
        startDate = moment(dates[0], 'DD-MM-YYYY').toDate()
        endDate = moment(dates[2], 'DD-MM-YYYY').toDate()
      } else if (dates.length === 1) {
        startDate = moment(dates[0], 'DD-MM-YYYY').toDate()
        endDate = moment(dates[0], 'DD-MM-YYYY').toDate()
      }
    }

    axios
      .post('/novels/download-excel/swab-list', {
        search: searchQuery.value,
        team: filterTeam.value,
        filterPatientType: filterPatientType.value,
        filterCovidCurrentLevel: filterCovidCurrentLevel.value,
        filterCovidCurrentState: filterCovidCurrentState.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        startDate,
        endDate,
      })
      .then(response => {
        const { data } = response
        return FileSaver.saveAs(data.url, `export-covid-novel-${(new Date()).getTime()}.xlsx`)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error download excel',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const downloadExcelDailyInvestigateReportList = () => {
    let startDate = null
    let endDate = null
    if (filterRangeDate.value) {
      const dates = filterRangeDate.value.split(' ')
      if (dates.length === 3) {
        startDate = moment(dates[0], 'DD-MM-YYYY').toDate()
        endDate = moment(dates[2], 'DD-MM-YYYY').toDate()
      } else if (dates.length === 1) {
        startDate = moment(dates[0], 'DD-MM-YYYY').toDate()
        endDate = moment(dates[0], 'DD-MM-YYYY').toDate()
      }
    }

    axios
      .post('/novels/download-excel/daily-investigate-report-list', {
        search: searchQuery.value,
        team: filterTeam.value,
        filterPatientType: filterPatientType.value,
        filterCovidCurrentLevel: filterCovidCurrentLevel.value,
        filterCovidCurrentState: filterCovidCurrentState.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        startDate,
        endDate,
      })
      .then(response => {
        const { data } = response
        return FileSaver.saveAs(data.url, `export-covid-novel-${(new Date()).getTime()}.xlsx`)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error download excel',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteNovel = novel => {
    axios
      // eslint-disable-next-line no-underscore-dangle
      .delete(`/novels/detail/${novel._id}`)
      .then(() => {
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error delete novel',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const submitSearch = () => {
    refetchData()
  }

  const submitExportExcelSwabList = () => {
    refetchData()
    downloadExcelSwabList()
  }

  const submitExportExcelDailyInvestigateReportList = () => {
    refetchData()
    downloadExcelDailyInvestigateReportList()
  }

  const fetchNovels = (ctx, callback) => {
    let startDate = null
    let endDate = null
    if (filterRangeDate.value) {
      const dates = filterRangeDate.value.split(' ')
      if (dates.length === 3) {
        startDate = moment(dates[0], 'DD-MM-YYYY').toDate()
        endDate = moment(dates[2], 'DD-MM-YYYY').toDate()
      } else if (dates.length === 1) {
        startDate = moment(dates[0], 'DD-MM-YYYY').toDate()
        endDate = moment(dates[0], 'DD-MM-YYYY').toDate()
      }
    }
    axios
      .post('/novels/list', {
        search: searchQuery.value,
        team: filterTeam.value,
        filterPatientType: filterPatientType.value,
        filterCovidCurrentLevel: filterCovidCurrentLevel.value,
        filterCovidCurrentState: filterCovidCurrentState.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        startDate,
        endDate,
      })
      .then(response => {
        const { rows, total } = response.data

        callback(rows)
        totalNovels.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching novels list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchNovels,
    submitSearch,
    submitExportExcelSwabList,
    submitExportExcelDailyInvestigateReportList,
    deleteNovel,
    getTeamChoices,
    userData,
    tableColumns,
    pageSize,
    totalNovels,
    paginationMeta,
    currentPage,
    pageSizeOptions,
    searchQuery,
    filterTeam,
    filterPatientType,
    filterCovidCurrentLevel,
    filterCovidCurrentState,
    teamChoices,
    patientTypesChoices,
    covidCurrentStateChoices,
    covidCurrentLevelChoices,
    sortBy,
    isSortDirDesc,
    refNovelListTable,
    filterRangeDate,
    filterRangeConfig,
    maxFilterDate,

    refetchData,
  }
}
