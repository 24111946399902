<template>
  <div>
    <b-card
      title="Novel"
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-button
              variant="primary"
              :to="{ name: 'staff-novel-full-form-create-new'}"
            >
              <span class="text-nowrap">สร้างรายการใหม่</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
            class="mt-1 mt-md-0"
          >
            <div class="d-flex align-items-center justify-content-end">
              <flat-pickr
                id="v-risk-info-r1-lastContactCovid19patient"
                v-model="filterRangeDate"
                class="form-control"
                placeholder="ช่วงวันที่"
                locale="th"
                reset-button
                close-button
                hide-header
                :config="filterRangeConfig"
              />

              <b-form-select
                v-if="userData.role && userData.role === 'admin'"
                v-model="filterTeam"
                class="d-inline-block ml-1"
                placeholder="หน่วยงาน"
                :options="teamChoices"
              />

              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-0 ml-1"
                placeholder="เลขบัตรประชาชน / Passport / Patient Code"
              />
            </div>
            <div class="mt-1">
              <b-row>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-select
                    v-model="filterPatientType"
                    label="text"
                    placeholder="ประเภท"
                    :options="patientTypesChoices"
                    :reduce="choice => choice.value"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="5"
                >
                  <v-select
                    v-model="filterCovidCurrentState"
                    label="text"
                    placeholder="สถานะ"
                    :options="covidCurrentStateChoices"
                    :reduce="choice => choice.value"
                    :clearable="true"
                    multiple
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="5"
                >
                  <v-select
                    v-model="filterCovidCurrentLevel"
                    label="text"
                    placeholder="ระดับอาการ"
                    :options="covidCurrentLevelChoices"
                    :reduce="choice => choice.value"
                    :clearable="true"
                    multiple
                  />
                </b-col>
              </b-row>
            </div>
            <div class="mt-1 d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                class="d-none d-md-inline"
                @click="submitSearch"
              >
                <span class="text-nowrap">ค้นหา</span>
              </b-button>

              <b-dropdown
                right
                variant="outline-success"
                text="Export"
              >
                <b-dropdown-item @click="submitExportExcelSwabList">
                  รายชื่อส่งตรวจ Swab
                </b-dropdown-item>
                <b-dropdown-item @click="submitExportExcelDailyInvestigateReportList">
                  รายงานสอบสวนโรครายวัน ศบส
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              class="d-inline d-md-none mt-1 mt-md-0"
              block
              @click="submitSearch"
            >
              <span class="text-nowrap">ค้นหา</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="d-inline d-md-none mt-1 mt-md-0"
              block
              @click="submitSearch"
            >
              <span class="text-nowrap">Export Excel</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refNovelListTable"
        class="position-relative"
        :items="fetchNovels"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(createDate)="data">
          <span>{{ data.item.createdAt | moment("DD-MM-YYYY HH:mm") }}</span>
        </template>

        <!-- Column: No.Covid -->
        <template #cell(noCovid)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'staff-novel-full-form', params: { novelId: data.item._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.covidCaseCode || "" }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: ID -->
        <template #cell(uniqueId)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'staff-novel-full-form', params: { novelId: data.item._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.uniqueId }}
            </b-link>
            <small v-if="userData.role && userData.role === 'admin'">{{ data.item.teamName }}</small>
          </b-media>
        </template>

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'staff-novel-full-form', params: { novelId: data.item._id } }"
              class="d-block text-nowrap"
            >
              {{ [data.item.name, data.item.lastName].join(' ') }}
            </b-link>
            <div>
              <b-badge
                v-if="data.item.isCovid"
                variant="info"
              >
                COVID
              </b-badge>
              <b-badge
                v-else
                variant="default"
                style="background-color: cornflowerblue"
              >
                NON-COVID
              </b-badge>
              -
              <b-badge
                v-if="data.item.covidCurrentLevel === 'green'"
                variant="success"
              >
                เขียว
              </b-badge>
              <b-badge
                v-else-if="data.item.covidCurrentLevel === 'yellow'"
                variant="warning"
              >
                เหลือง
              </b-badge>
              <b-badge
                v-else-if="data.item.covidCurrentLevel === 'red'"
                variant="danger"
              >
                แดง
              </b-badge>
              -
              <b-badge
                v-if="data.item.covidCurrentState === 'wait_bed'"
                variant="dark"
              >
                รอเตียง
              </b-badge>
              <b-badge
                v-else-if="data.item.covidCurrentState === 'hi'"
                variant="dark"
              >
                Home Isolation
              </b-badge>
              <b-badge
                v-else-if="data.item.covidCurrentState === 'hq'"
                variant="dark"
              >
                Home Quarantine
              </b-badge>
              <b-badge
                v-else-if="data.item.covidCurrentState === 'ci'"
                variant="dark"
              >
                Community Isolation
              </b-badge>
              <b-badge
                v-else-if="data.item.covidCurrentState === 'admit'"
                variant="dark"
              >
                Admit
              </b-badge>
              <b-badge
                v-else-if="data.item.covidCurrentState === 'end'"
                variant="dark"
              >
                จบเคส
              </b-badge>
              <b-badge
                v-else-if="data.item.covidCurrentState === 'death'"
                variant="dark"
              >
                ตาย
              </b-badge>
            </div>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            :to="{ name: 'staff-novel-full-form', params: { novelId: data.item._id } }"
            size="sm"
            variant="success"
            class=""
          >
            <span class="align-middle">ดูข้อมูล</span>
          </b-button>
          <b-button
            size="sm"
            class="ml-0 ml-md-1"
            variant="danger"
            @click="confirmDeleteNovel(data.item)"
          >
            <span class="align-middle">ลบ</span>
          </b-button>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ paginationMeta.from }} to {{ paginationMeta.to }} of {{ paginationMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="String(pageSize)"
              right
              variant="outline-primary"
            >
              <b-dropdown-item
                v-for="pageSizeOption in pageSizeOptions"
                :key="String(pageSizeOption)"
                @click="pageSize=pageSizeOption"
              >
                {{ pageSizeOption }}
              </b-dropdown-item>
            </b-dropdown>

            <b-pagination
              v-model="currentPage"
              :total-rows="totalNovels"
              :per-page="pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 ml-2"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BBadge, BButton, BFormInput, BCol, BRow, BTable, BPagination, BLink, BDropdown, BDropdownItem, BMedia, BFormSelect,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useNovelList from '@/composables/useNovelList'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import FileSaver from 'file-saver'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BLink,
    BDropdown,
    BDropdownItem,
    BMedia,
    BFormSelect,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
      ...useNovelList(FileSaver),
      avatarText,
    }
  },
  methods: {
    confirmDeleteNovel(novel) {
      this.$bvModal
        .msgBoxConfirm(`ยืนยันที่จะลบรายการของ ${[novel.uniqueId, [novel.name, novel.lastName].join(' ')].join(' ')} ใช่หรือไม่`, {
          title: 'ยืนยันการลบ',
          size: 'md',
          okVariant: 'danger',
          okTitle: 'ยืนยันลบ',
          cancelTitle: 'ไม่,ยกเลิก',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteNovel(novel)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.v-novel-list-column-createdAt {
  min-width: 120px;
  width: 140px;
}
.v-novel-list-column-noCovid {
  width: 100px;
}
.v-novel-list-column-uniqueId {
  width: 140px;
}
.v-novel-list-column-action {
  min-width: 190px;
  width: 200px;
  text-align: right;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
